//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      filters: {
        searchString: undefined,
        type: undefined,
        status: undefined,
        dateFrom: undefined,
        dateTo: undefined,
      },
    };
  },

  computed: {
    ...mapState('userDevices', ['filterLists']),

    ...mapState('tradersRequisites', { requisites: 'data' }),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { paymentMethods: 'data' }),

    getTypes() {
      return Object.entries(constant.traders.DEVICE_TYPE_TITLES)
        .filter(([key]) => !key.includes('DEVICE_TYPE'))
        .map(([key, value]) => ({ id: parseInt(key, 10), name: value }));
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userDevices', ['setFilters']),
  },
};
