export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Name',
    },
    cell: {
      type: 'text',
      value: (e) => e.name,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Model',
    },
    cell: {
      type: 'text',
      value: (e) => e.model,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Type',
    },
    cell: {
      type: 'slot',
      name: 'type',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Updated',
    },
    cell: {
      type: 'slot',
      name: 'updated',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Number',
    },
    cell: {
      type: 'text',
      value: (e) => e.number,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'IP Address',
    },
    cell: {
      type: 'text',
      value: (e) => e.ip,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Key',
    },
    cell: {
      type: 'text',
      value: (e) => e.key,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Action',
    },
    cell: {
      type: 'slot',
      name: 'action',
    },
  },
];
