//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        // searchString: '',
        currency: '',
        paymentMethodId: [],
        status: '',
        dateFrom: '',
        dateTo: '',
      },
      constant,
    };
  },

  computed: {
    ...mapState('userRequisites', ['filterLists']),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { methods: 'data' }),

  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('userRequisites', ['setFilters']),
  },
};
