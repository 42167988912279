export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Offer id',
    },
    cell: {
      type: 'slot',
      name: 'offerId',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Seller amount',
    },
    cell: {
      type: 'text',
      value: (e) => `${e.sellerAmount} ${e.sellerCurrency}`,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Buyer amount',
    },
    cell: {
      type: 'text',
      value: (e) => `${e.buyerAmount} ${e.buyerCurrency}`,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Seller name',
    },
    cell: {
      type: 'text',
      value: (e) => e.sellerName,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Buyer name',
    },
    cell: {
      type: 'text',
      value: (e) => e.buyerName,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'text',
      value: (e) => e.status,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Create date',
    },
    cell: {
      type: 'slot',
      name: 'createDate',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Expiration date',
    },
    cell: {
      type: 'slot',
      name: 'expirationDate',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Arbitrator ID',
    },
    cell: {
      type: 'text',
      value: (e) => e.arbitratorId,
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Arbitrator Username',
    },
    cell: {
      type: 'text',
      value: (e) => e.arbitratorUsername,
      tooltip: true,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Chat',
    },
    cell: {
      type: 'slot',
      name: 'chat',
    },
  },
];
