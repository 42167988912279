//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      constant,
      filters: {
        searchString: '',
        userId: '',
        buyerCurrency: [],
        sellerCurrency: [],
        requisiteId: [],
        dateFrom: '',
        dateTo: '',
        requisites: [],
        status: [],
      },
    };
  },

  computed: {
    ...mapState('tradersDeals', ['filterLists']),

    ...mapState('tradersRequisites', { requisites: 'data' }),
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { paymentMethods: 'data' }),

    getStatuses() {
      return Object.keys(constant.traders.TRADE_STATUS_IOTA).map((key) => ({
        id: parseInt(key, 10),
        name: constant.traders.TRADE_STATUS_IOTA[key],
      }));
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters(data);
        this.$emit('update');
      },
      deep: true,
    },
  },

  created() {
    this.setFilters({});
  },

  methods: {
    ...mapActions('tradersDeals', ['setFilters']),
  },
};
