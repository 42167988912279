//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import TableKeyValue from '~/components/ui/TableKeyValue.vue';

export default {
  components: {
    TableKeyValue,
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('userStatistics', ['p2pInfoTable', 'p2pDisputesTable']),
    ...mapState('userStatistics', ['userP2P']),
  },
};
