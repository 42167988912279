var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Requisites'))+" ")])]),_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}}),_c('div',{staticClass:"ml-10 mt-3"},[_c('v-btn',{attrs:{"color":"primary","height":"40px","outlined":""},on:{"click":_vm.openCreateRequisiteModal}},[_vm._v(" "+_vm._s(_vm.$t('Create'))+" ")])],1)],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('DropdownList',{attrs:{"config":_vm.actionsConfig},on:{"action":_vm.actionHandler}})]},proxy:true},{key:"limits",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption mt-1 mb-1",staticStyle:{"line-height":"1.1"}},[_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Min'))+": ")]),_vm._v(_vm._s(row.minLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Day'))+": ")]),_vm._v(_vm._s(row.dayLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Week'))+": ")]),_vm._v(_vm._s(row.weekLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Month'))+": ")]),_vm._v(_vm._s(row.monthLimit)+" ")])])]}},{key:"operationLimits",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption",staticStyle:{"line-height":"1.1"}},[_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Day'))+": ")]),_vm._v(_vm._s(row.dayOperationLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Week'))+": ")]),_vm._v(_vm._s(row.weekOperationLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Month'))+": ")]),_vm._v(_vm._s(row.monthOperationLimit)+" ")])])]}},{key:"userId",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":Number(row.userId),"username":row.userId}})]}},{key:"deviceId",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"cursor":"pointer","color":"var(--v-anchor-base)","text-decoration":"underline"},on:{"click":function($event){$event.stopPropagation();return _vm.openDeviceById(row.deviceId)}}},[_vm._v(" "+_vm._s(row.deviceId)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.statusClass(row.status)},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.REQUISITE_TYPE_IOTA[row.status]))+" ")])]}},{key:"paymentMethodStatus",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.paymentMethodStatusClass(row.paymentMethod ? row.paymentMethod.status : '')},[_vm._v(" "+_vm._s(_vm.$t(row.paymentMethod ? row.paymentMethod.status : ''))+" ")])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.openModalEditRequisite(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.deleteRequisite(row)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editingRequisite.data,"is-creating":_vm.editingRequisite.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingRequisite.show),callback:function ($$v) {_vm.$set(_vm.editingRequisite, "show", $$v)},expression:"editingRequisite.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }