//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';
import User from '~/components/User.vue';
import Filters from './TableFilters.vue';
import Modal from './Modal.vue';

import tableConfig from './tableConfig';
import actionsConfig from './actionsConfig';

export default {
  components: { Filters, Modal, User },

  data() {
    return {
      editingRequisite: {
        show: false,
        data: {},
        isCreating: false,
      },
      tableConfig,
      actionsConfig,
      selection: [],
      loader: false,
      constant,
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),

    ...mapState('userRequisites', ['page', 'pages', 'data', 'sort']),
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
      'confirmAction',
    ]),
    ...mapActions('userRequisites', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      editRequisiteAction: 'editRequisite',
      createRequisiteAction: 'createRequisite',
      deleteRequisiteAction: 'deleteRequisite',
    }),

    statusClass(status) {
      switch (status) {
        case 0:
          return 'success--text';
        case 1:
          return 'warning--text';
        case 2:
        case 3:
          return 'error--text';
        default:
          return '';
      }
    },

    async loadData() {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(this.$router.currentRoute.params.id);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }
      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    openCreateRequisiteModal() {
      this.editingRequisite.show = true;
      this.editingRequisite.data = null;
      this.editingRequisite.isCreating = true;
    },

    openModalEditRequisite(row) {
      this.editingRequisite.show = true;
      this.editingRequisite.data = { ...row };
      this.editingRequisite.isCreating = false;
    },

    applyEdit({ input }) {
      const { isCreating } = this.editingRequisite;
      this.confirmAction({
        title: isCreating
          ? 'Are you sure you want to create requisite?'
          : 'Are you sure you want to edit requisite?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            if (isCreating) {
              await this.createRequisiteAction(input);
            } else {
              await this.editRequisiteAction(input);
            }
            this.editingRequisite.show = false;
            await this.loadData();
            if (isCreating) {
              this.setSuccessNotification('Requisite created');
            } else {
              this.setSuccessNotification('Requisite edited');
            }
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    deleteRequisite(row) {
      this.confirmAction({
        title: 'Are you sure you want to delete requisite?',
        callback: async () => {
          this.setGeneralProgress(true);
          this.loader = true;
          try {
            await this.deleteRequisiteAction({ requisitesId: row.id, userId: row.userId });
            await this.loadDataAction({});
            this.setSuccessNotification(`Requisite ${row.id} deleted`);
          } catch (error) {
            this.setErrorNotification(error.message);
          }
          this.setGeneralProgress(false);
          this.loader = false;
        },
      });
    },

    paymentMethodStatusClass(status) {
      return status === this.constant.traders.PAYMENT_METHOD_STATUS.ENABLED
        ? 'success--text'
        : 'error--text';
    },
  },
};
