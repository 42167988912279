export default [
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Payment method',
    },
    cell: {
      type: 'text',
      value: (e) => e.paymentMethod?.name,
    },
  },
  {
    width: 28,
    header: {
      type: 'text',
      caption: 'Payment method status',
    },
    cell: {
      type: 'slot',
      name: 'paymentMethodStatus',
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Card number',
    },
    cell: {
      type: 'text',
      value: (e) => e.cardNumber,
      tooltip: true,
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Card holder',
    },
    cell: {
      type: 'text',
      value: (e) => e.cardHolder,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'User id',
    },
    cell: {
      type: 'slot',
      name: 'userId',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Limits',
    },
    cell: {
      type: 'slot',
      name: 'limits',
    },
  },
  {
    width: 12,
    header: {
      type: 'text',
      caption: 'Operation limits',
    },
    cell: {
      type: 'slot',
      name: 'operationLimits',
    },
  },
  {
    width: 13,
    header: {
      type: 'text',
      caption: 'Device id',
    },
    cell: {
      type: 'slot',
      name: 'deviceId',
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 20,
    header: {
      type: 'text',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
