var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Offers'))+" ")])]),_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{ref:"offersTable",attrs:{"selection":_vm.selection,"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"sort":_vm.sort,"show-extra":"","show-select":"","fixed-header":"","height":"527px"},on:{"update:selection":function($event){_vm.selection=$event},"sort":_vm.applySort},scopedSlots:_vm._u([{key:"extra",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mt-4 mb-4"},[_c('UiTable',{attrs:{"data":row.requisites,"config":_vm.requisitesTableConfig},scopedSlots:_vm._u([{key:"userId",fn:function(ref){
var row = ref.row;
return [_c('User',{attrs:{"id":Number(row.userId),"username":row.userId}})]}},{key:"limits",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption mt-1 mb-1",staticStyle:{"line-height":"1.1"}},[_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Min'))+": ")]),_vm._v(_vm._s(row.minLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Day'))+": ")]),_vm._v(_vm._s(row.dayLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Week'))+": ")]),_vm._v(_vm._s(row.weekLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Month'))+": ")]),_vm._v(_vm._s(row.monthLimit)+" ")])])]}},{key:"operationLimits",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"text-caption",staticStyle:{"line-height":"1.1"}},[_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Day'))+": ")]),_vm._v(_vm._s(row.dayOperationLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Week'))+": ")]),_vm._v(_vm._s(row.weekOperationLimit)+" ")]),_c('div',{},[_c('span',{staticClass:"text--disabled"},[_vm._v(_vm._s(_vm.$t('Month'))+": ")]),_vm._v(_vm._s(row.monthOperationLimit)+" ")])])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.statusClass(row.status)},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.REQUISITE_TYPE_IOTA[_vm.constant.traders.REQUISITE_TYPE[row.status]]))+" ")])]}},{key:"paymentMethodStatus",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.paymentMethodStatusClass(row.paymentMethod ? row.paymentMethod.status : '')},[_vm._v(" "+_vm._s(_vm.$t(row.paymentMethod ? row.paymentMethod.status : ''))+" ")])]}}],null,true)})],1)]}},{key:"customActions",fn:function(){return [_c('DropdownMenu',{attrs:{"class-name":"d-inline-block","config":_vm.actionsConfig},on:{"updateOffers":_vm.updateOffers}},[_c('v-icon',[_vm._v("mdi-dots-vertical")]),_vm._v(" "+_vm._s(_vm.$t('Actions'))+" ")],1)]},proxy:true},{key:"customCell",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.id)+" ")]}},{key:"flowType",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.FLOW_TYPE_IOTA[row.FlowType]))+" ")]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{class:_vm.statusClass(row.status ? row.status : '')},[_vm._v(" "+_vm._s(_vm.$t(_vm.constant.traders.OFFER_STATUS_IOTA[row.status]))+" ")])]}},{key:"edit",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"mr-2",attrs:{"outlined":"","color":"warning"},on:{"click":function($event){$event.stopPropagation();return _vm.openModalEditOffer(row)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1),_c('Modal',{attrs:{"data":_vm.editingOffer.data,"is-creating":_vm.editingOffer.isCreating},on:{"apply":_vm.applyEdit},model:{value:(_vm.editingOffer.show),callback:function ($$v) {_vm.$set(_vm.editingOffer, "show", $$v)},expression:"editingOffer.show"}}),_c('Modal',{attrs:{"is-mass-updating":true},on:{"apply":_vm.applyMassEdit},model:{value:(_vm.massEditingOffers.show),callback:function ($$v) {_vm.$set(_vm.massEditingOffers, "show", $$v)},expression:"massEditingOffers.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }