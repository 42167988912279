var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"d-flex justify-space-between mb-6"},[_c('h3',[_vm._v(" "+_vm._s(_vm.$t('Devices'))+" ")])]),_c('div',{staticClass:"section__filters"},[_c('Filters',{attrs:{"is-loading":_vm.loader},on:{"update":_vm.applyFilters}})],1),_c('div',{staticClass:"section__table"},[_c('UiTable',{attrs:{"data":_vm.data,"config":_vm.tableConfig,"is-loading":_vm.generalLoader || _vm.loader,"per-page":_vm.itemsPerPage,"fixed-header":"","height":"527px"},scopedSlots:_vm._u([{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.status))])]}},{key:"created",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.created))+" ")])]}},{key:"updated",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.$utils.getDate(row.updated))+" ")])]}},{key:"type",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(" "+_vm._s(_vm.constant.traders.DEVICE_TYPE_LIST[row.type].caption)+" ")])]}},{key:"action",fn:function(){return [_c('v-btn',{attrs:{"outlined":"","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t('Delete'))+" ")])]},proxy:true}])})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('ContentPagination',{attrs:{"page":_vm.page,"pages":_vm.pages,"per-page":""},on:{"input":_vm.setPage,"inputPerPage":_vm.applyFilters}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }