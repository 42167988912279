//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex';

import UserDevices from '~/views/UserTrader/UserDevices/UserDevicesTable.vue';
import UserOffers from '~/views/UserTrader/UserOffers/Table.vue';
import UserDeals from '~/views/UserTrader/UserDeals/Table.vue';
import UserRequisites from '~/views/UserTrader/UserRequisites/Table.vue';
import UserStatistics from '~/views/UserTrader/UserStatistics/AccountInfo.vue';

export default {
  components: {
    UserDeals,
    UserOffers,
    UserDevices,
    UserRequisites,
    UserStatistics,
  },

  computed: {
    ...mapState('user', ['user']),
    routerId() {
      return this.$route.params.id;
    },
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadUserDevices({ userId: this.user?.id }),
      this.loadTradersOffers({ userId: this.user?.id }),
      this.loadDeals({ userId: this.user?.id }),
      this.loadRequisites({ userId: this.user?.id }),
      this.loadCurrencies(),
      this.loadPaymentMethods(),
      this.loadUserStatistics(this.user?.id),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),

    ...mapActions('tradersDeals', {
      loadDeals: 'loadData',
    }),
    ...mapActions('tradersOffers', {
      loadTradersOffers: 'loadData',
    }),
    ...mapActions('userDevices', {
      loadUserDevices: 'loadData',
    }),
    ...mapActions('userRequisites', {
      loadRequisites: 'loadData',
    }),
    ...mapActions('tradersCurrencies', {
      loadCurrencies: 'loadData',
    }),
    ...mapActions('tradersPaymentMethods', {
      loadPaymentMethods: 'loadData',
    }),
    ...mapActions('userStatistics', {
      loadUserStatistics: 'loadUserP2PData',
    }),
  },
};
