//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import constant from '~/const';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      input: {},
      constant,
    };
  },

  computed: {
    ...mapState('tradersCurrencies', { currencies: 'data' }),
    ...mapState('tradersPaymentMethods', { p2pPaymentMethods: 'data' }),

    model: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    additionalFieldsForCreatingInput() {
      return {
        userId: this.$router.currentRoute.params.id,
        name: '',
        linkToOffer: false,
        deviceId: '',
        enableSell: true,
        enableBuy: true,
      };
    },
    defaultEditingInput() {
      return {
        paymentMethodId: 1,
        currency: 'RUB',
        cardNumber: '',
        cardHolder: '',
        dayLimit: 0,
        weekLimit: 0,
        monthLimit: 0,
        minLimit: 0,
        dayOperationLimit: 0,
        weekOperationLimit: 0,
        monthOperationLimit: 0,
        status: constant.traders.REQUISITE_TYPE_IOTA[constant.traders.REQUISITE_TYPE.REQUISITE_STATUS_ENABLED],
      };
    },
    isApplyPossible() {
      return this.isCreating
        ? this.input?.name && this.input?.cardNumber && this.input?.cardHolder
        : this.input?.cardNumber && this.input?.cardHolder;
    },
  },

  watch: {
    value(val) {
      if (val) {
        const {
          isCreating, defaultEditingInput, additionalFieldsForCreatingInput, data,
        } = this;
        if (isCreating) {
          this.$set(this, 'input', { ...additionalFieldsForCreatingInput, ...defaultEditingInput });
        } else {
          const model = { requisitesId: data.id };
          Object.keys(defaultEditingInput).forEach((key) => {
            model[key] = data[key];
            model.paymentMethodId = data.paymentMethod.id;
          });
          this.$set(this, 'input', model);
        }
      }
    },
  },

  methods: {
    apply() {
      const { input } = this;
      this.$emit('apply', { input });
    },
  },
};
